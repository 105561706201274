import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from '@components/home/auth-callback.component';

import { AuthGuardService } from '@services/app/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';

import { HomeComponent } from '@components/home/home.component';
import { ClientsComponent } from '@components/clients/clients.component';
import { ContactsComponent } from '@components/contacts/contacts.component';
import { RequetesComponent } from '@components/requetes/requetes.component';
import { AgendaComponent } from '@components/agenda/agenda.component';
import { DossiersComponent } from '@components/dossiers/dossiers.component';
import { ParametersComponent } from '@components/parametres/parameters.component';
import { LogoutComponent } from '@components/home/logout.component';
import { CalendarModule } from 'angular-calendar';

const appRoutes: Routes = [
  { path: 'auth-callback', data: { breadcrumb: '' }, component: AuthCallbackComponent },
  { path: 'logout', data: { breadcrumb: '' }, component: LogoutComponent },

  { 
    path: 'agenda', data: { breadcrumb: $localize `Agenda` }, component: AgendaComponent, canActivate: [AuthGuardService],
    loadChildren: () => import('@components/agenda/agenda.module').then(m => m.AgendaModule)
  },
  { 
    path: 'clients', data: { breadcrumb: $localize `Clients` }, component: ClientsComponent, canActivate: [AuthGuardService],
    loadChildren: () => import('@components/clients/clients.module').then(m => m.ClientsModule)
  },
  { 
    path: 'contacts', data: { breadcrumb: $localize `Contacts` }, component: ContactsComponent, canActivate: [AuthGuardService],
    loadChildren: () => import('@components/contacts/contacts.module').then(m => m.ContactsModule)
  },
  { 
    path: 'folders', data: { breadcrumb: $localize `Dossiers` }, component: DossiersComponent, canActivate: [AuthGuardService],
    loadChildren: () => import('@components/dossiers/dossiers.module').then(m => m.DossiersModule)
  },
  { 
    path: 'queries', data: { breadcrumb: $localize `Requêtes` }, component: RequetesComponent, canActivate: [AuthGuardService],
    loadChildren: () => import('@components/requetes/requetes.module').then(m => m.RequetesModule)
  },
  { 
    path: 'parameters', data: { breadcrumb: $localize `Paramètres` }, component: ParametersComponent, canActivate: [AuthGuardService],
    loadChildren: () => import('@components/parametres/parameters.module').then(m => m.ParametersModule)
  },
  {  path: '', data: { breadcrumb: $localize `Accueil` }, component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
]

@NgModule({
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}